$('#add-domain').click(function() {
    $('#domains').append('<div><div class="form-group col-md-10"><label for="domains"></label><input name="domains[]" type="text" class="form-control input-lg" placeholder="google.com.br"></div><div class="form-group"><div class="btn btn-danger add-button" onclick="deleteElement(this)">Del</div></div></div>');
});

$('#add-keys').click(function() {
    $('#sms_keys').append('<div><div class="form-group col-md-10"><label for="sms_keys"></label><input name="sms_keys[]" type="text" class="form-control input-lg" placeholder="verificar instruções"></div><div class="form-group"><div class="btn btn-danger add-button" onclick="deleteElement(this)">Del</div></div></div>');
});

$('#add-hash').click(function() {
    $('#keystore_hashes').append('<div><div class="form-group col-md-10"><label for="keystore_hash"></label><input type="text" name="keystore_hash[]" class="form-control input-lg" placeholder="aGF2ZW4gbWFrcm9zeXN0ZW1zCg==" required></div><div class="form-group"><div class="btn btn-danger add-button" onclick="deleteElement(this)">Del</div></div></div>');
});

function deleteElement(object) {
    $(object).parent().parent().remove();
}

function load_package_name() {
    var concated_domain = "";
    var app_name = $("input[name='app_name']").val().split(' ').join('');
    var domain_name = $("input[name='domain_name']").val().split('.').reverse();

    domain_name.forEach(function(partial) {
        concated_domain += partial + ".";
    });

    return $('#package_name_label').val( concated_domain + app_name );
}
